.addlightsource-form{
    @include login-home-formlabel;
    .required{
        &::after{
            content: " *";
            color: #e72729;
            font-size: 20px;
        }
    }
    input{
        width: 350% !important;
    }
    .has-search {
        width: 100%;
        margin-right: 1rem;
        margin-bottom: 0;
        position: relative;
    }
    .form-control-feedback {
        position: absolute;
        bottom: 2px;
        z-index: 2;
        display: block;
        right: 1rem;
        line-height: 3rem;
        font-size: 1.1rem;
        text-align: center;
        pointer-events: none;
        color:#707070;
    }
    .form-group{
        padding-top: 20px;
        margin-bottom: 0;
    }
    @include custom-select;
    .btn{
        @include form-btn;
    }
    .search-icon {
        font-size: 1.1rem;
        color:#707070;
    }
}

.lights-modal {
    .modal-dialog{
        max-width: unset;
        margin: 0 auto;
        width: 775px;
        height: 700px;
        margin-left: 17%;
    }
    .modal-content{
        display: initial !important;
        background-color: transparent !important;
    }
}
.addlightsource{
    width: 775px;
    height: 700px;
    background: #252525 0% 0% no-repeat padding-box;
    box-shadow: 0px 0px 50px #00000080;
    border-radius: 50px;
    padding: 50px 87px !important;
    margin-top: 10%;
    .modal-dialog{
        max-width: unset;
    }
    .file-input-container{
        display: flex;
        justify-content: center;
      }
      .sm-input-file{
          border: 1px solid #333;
          background: #ccc;
          color: #777;
          opacity:0;
          width: 20px !important;
      }
      .for-sm-input-file{
        position: absolute;
        cursor: pointer;
        top: -10px;
        line-height: 39px;
        // z-index: 30;
        text-align: center;
      }

      .span-text{
        display: -webkit-inline-box;
        display: -ms-inline-flexbox;
        display: inline-flex;
        color: #fff;
        position: absolute;
        bottom: -40px;
      }
    .modal-content{
        background: unset;
        border: unset;
        .close{
                padding: 0;
                background-color: transparent;
                border: 0;
                opacity: 1;
                color: $bg-red;
                text-shadow: none;
                &:focus{
                    outline: unset;
                    opacity: 1;
                }
        }
        .modal-title{
            margin-top: 16px;
            font-family: $font-family;
            font-size: 50px;
            line-height: 61px;
            font-weight: bold;
            letter-spacing: 0px;
        }

    }

}
.select-box-lights {
    width: 100% !important;
}
