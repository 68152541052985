@mixin form-btn {
    box-shadow: 0px 0px 10px #00000033;
    border-radius: 10px;
    font-family: $font-family;
    font-size: 15px;
    line-height: 18px;
    letter-spacing: 0.75px;
    font-weight: 800;
    text-transform: uppercase;
    color: white;
    width: 190px;
    height: 60px;
    margin-top: 14px;
}

#saveas-modal, #shareConfiguration-modal, #previewLightbar-modal {
    width: 700px;
    max-height: 800px;
    background: #252525 0% 0% no-repeat padding-box;
    box-shadow: 0px 0px 50px #00000080;
    border-radius: 50px;
    padding: 50px 87px !important;
    margin-top: 10% !important;

    .modal-dialog{
        max-width: unset;
    }
    .modal-content{
        background: unset;
        border: unset;
        .close{
          padding: 0;
          background-color: transparent;
          border: 0;
          opacity: 1;
          color: $bg-red;
          text-shadow: none;
          &:focus{
              outline: unset;
              opacity: 1;
          }
        }
        .modal-title{
          margin-top: 16px;
          font-family: $font-family;
          font-size: 50px;
          line-height: 61px;
          font-weight: bold;
          letter-spacing: 0px;
        }
    }
      p{
        margin-top: 10px;
        font-size: 14px;
        line-height: 17px;
        letter-spacing: 0px;
        margin-right: 1rem;
        font-weight: unset;
        color: white;
    }
  }

  #saveas-modal, #shareConfiguration-modal, #previewLightbar-modal {
    .rotation-option{
        display: flex;
        label{
            @include login-home-formlabel;
            font-weight: 400;
        }
        margin-bottom: 30px !important;
    }

    .rotation-option-delete {
      display: flex;

      label {
        @include login-home-formlabel;
        font-weight: 400;
      }
      margin-top: 20px !important;

    }

    .btn{
        @include form-btn;
    }
    .checkbox-custom {
      zoom: 2;
      margin: 5px;
    }
    .check-height {
      height: 30px;
    }
  }

  .saveasModal {
    .modal-dialog{
        max-width: unset;
        margin: 0 auto;
        margin-left: -500px;
    }
    .modal-content{
        display: initial !important;
        background-color: transparent !important;
    }
  }

    #delete-modal {
      width: 700px;
      max-height: 800px;
      background: #252525 0% 0% no-repeat padding-box;
      box-shadow: 0px 0px 50px #00000080;
      border-radius: 50px;
      padding: 50px 87px !important;
  
      .modal-dialog {
        max-width: unset;
      }
  
      .modal-content {
        background: unset;
        border: unset;
        top: 50%;
        transform: translateY(-50%);
  
        .close {
          padding: 0;
          background-color: transparent;
          border: 0;
          opacity: 1;
          color: $bg-red;
          text-shadow: none;
  
          &:focus {
            outline: unset;
            opacity: 1;
          }
        }
  
        .modal-title {
          margin-top: 16px;
          font-family: $font-family;
          font-size: 50px;
          line-height: 61px;
          font-weight: bold;
          letter-spacing: 0px;
        }
      }
  
      .btn {
        box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
        border-radius: 10px;
        font-family: "proxima-nova";
        font-size: 15px;
        line-height: 18px;
        letter-spacing: 0.75px;
        font-weight: 800;
        text-transform: uppercase;
        color: white;
        width: 190px;
        height: 60px;
        margin-top: 14px;
      }
  
      p {
        margin-top: 10px;
        font-size: 14px;
        line-height: 17px;
        letter-spacing: 0px;
        margin-right: 1rem;
        font-weight: unset;
        color: white;
      }
    }
.font-weight-bold {
  font-weight: 600 !important;
}

.del-modal > {
  .modal-dialog {
    margin-left: 0;
    height: 100%;
    display: flex;
    align-items: center;
    min-height: calc(100% - 1rem);
  }
}

.header-50 {
  width: 50px !important;
}
.w-38 {
  width: 38px !important;
}
.del-icon {
  line-height: 1;
  text-align: center;
  vertical-align: middle;
  height: 16px;
}

.valign-middle {
  vertical-align: middle !important;
}