// Bootstrap
@import "../../../node_modules/bootstrap/scss/bootstrap.scss";

// Font Icon
@import "../../../node_modules/@fortawesome/fontawesome-free/css/all.min.css";

// Custom CSS
@import url("https://use.typekit.net/apm0zsy.css");
@import "./spinner.scss";


// @import "./zonepage.scss";

$font-family: "proxima-nova";
$bg-red:#E72729;
$bg-black:#000000;
body{
    font-size: 16px;
    font-family: $font-family;
    background: #1d1d1d !important;
}
a:hover {
  text-decoration: unset;
}

a:focus-visible {
  outline: none !important;
}

i:hover {
    outline: 0;
}
.form-control:focus{
    border-color: unset !important;
    box-shadow: unset !important;
}
.form-control:disabled{
    opacity :0.5;
}
.form-control.is-invalid {
    border: none !important;
    background-image: none !important;
    border-color: none !important;
}
.form-control.is-invalid:focus{
    border: none !important;
    background-image: none !important;
}

/* responsive variable*/
$extra-large-one: 1600px;
$extra-large-two: 1440px;
$extra-large-three: 1800px;
$large-one: 1366px;
$large-two: 1200px;

/* layout css */
@mixin theme-bg {
  background: url("../images/bg.png");
  background-size: cover;
  background-repeat: no-repeat;
}
@mixin login-homepage {
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  align-items: center;
  justify-content: center;
}
@mixin login-home-block {
    background: #252525 0% 0% no-repeat padding-box;
    box-shadow: 0px 0px 50px #00000080;
    border-radius: 50px;
    width: 652px;
    height: 760px;
    padding: 0 113px;
}
@mixin home-block {
  background: #252525 0% 0% no-repeat padding-box;
  box-shadow: 0px 0px 50px #00000080;
  border-radius: 50px;
  width: 652px;
  padding: 0 113px;
}
@mixin login-home-logo{
    img{
        width: 66.43px;
        height: 50px;
    }
}
@mixin login-home-title {
  font-family: $font-family;
  font-size: 50px;
  line-height: 50px;
  font-weight: bold;
  letter-spacing: 2.5px;
  color: white;
  text-transform: uppercase;
}
@mixin  login-home-bottom-logo {
    width: 150px;
}
.login-page {
  min-height: 100vh;
  height: auto;
  @include login-homepage;
  @include theme-bg;
  .login-block {
    @include login-home-block;
    @media screen and (max-width: $large-one) {
        margin-top: 45px;
      }
    .brand-logo {
      @include login-home-logo;
      margin: 50px auto;
    }
    .form-group{
      margin-bottom: 0 !important;
      height: 85px;
    }
    .login-title {
      @include login-home-title;
      margin-bottom: 30px;
    }
    .bottom-logo {
      margin: 50px 0 50px;
      @include login-home-bottom-logo;
    }
  }
  .toggle-pwd {
    border: none;
    background: inherit;
  }

  .pos-relative {
    position: relative;
  }
  .input-icon-addon {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    color: $text-muted;
    display: flex;
    align-items: center;
    justify-content: center;
    min-width: 2.5rem;
    pointer-events: none;

    &:last-child {
      left: auto;
      right: 0;
    }
    i:focus {
      outline: 0;
    }
  }
  .custom-i-addon {
    pointer-events: auto;
  }
}
.home-page {
  min-height: 100vh;
  height: auto;
  @include login-homepage;
  @include theme-bg;
  .home-block {
    @include home-block;
    @media screen and (max-width: $extra-large-three) {
      margin-top: 67px;
    }
    .brand-logo {
      @include login-home-logo;
      margin-top: 63px;
    }
      .home-subtitle {
      font-family: $font-family;
      font-size: 25px;
      line-height: 30px;
      font-weight: bold;
      letter-spacing: 1.25px;
      margin: 26px 0;
    }
    .home-title {
      @include login-home-title;
      margin-bottom: 26px;
    }
    .bottom-logo {
      @include login-home-bottom-logo;
      margin: 62px 0 40px;
    }
    .horizontal-line {
      border: 0;
      border-top: 1px solid #707070;
      margin: 31.5px auto;
      width: 150px;
    }
    .revisit-link {
      width: fit-content;
      p {
        font-size: 15px;
        line-height: 18px;
        letter-spacing: 0;
        font-family: $font-family;
        margin-top: 7px;
        margin-bottom: 1rem;
      }
      a {
        margin-top: 21px;
        font-size: 15px;
        line-height: 18px;
        letter-spacing: 0.38px;
        font-weight: 800;
        font-family: $font-family;
        color: #e72729;
        padding-left: 0.6rem;

        }
    }
  }
}
#configurationList{
  .has-search {
      width: 100%;
      margin-bottom: 0;
      position: relative;
      .form-control {
          padding-left: 2.375rem;
      }
      .form-control-feedback {
          position: absolute;
          z-index: 2;
          display: block;
          width: 2.375rem;
          height: 2.375rem;
          line-height: 3rem;
          text-align: center;
          pointer-events: none;
          color: #aaa;
      }
      .close-icon{
          color: #aaaaaa;
          position: absolute;
          z-index: 2;
          display: block;
          width: 2.375rem;
          height: 2.375rem;
          line-height: 3rem;
          color: #aaa;
          right: 0;
          top: 0;
      }
  }
  .modal-header,.modal-footer{
      justify-content: center;
      border: unset;
      .modal-title{
        font-family: "proxima-nova";
        font-size: 50px;
        line-height: 61px;
        font-weight: bold;
        letter-spacing: 0px;
        width: 100%;
        margin-top: 16px;
      }
  }
  .breadcrumb-item+.breadcrumb-item::before {
      color: white;
      content: "|";
  }
  .breadcrumb{
      background: unset;
      justify-content: flex-end;
      a{
          color: white;
          font-family: $font-family;
      }
      .breadcrumb-item.active{
          color: white;
      }
  }
  .modal-content{
      background: #252525 0% 0% no-repeat padding-box;
      -webkit-box-shadow: 0px 0px 50px #00000080;
      box-shadow: 0px 0px 50px #00000080;
      border-radius: 50px;
      height: 910px;
      margin-top: 5%;
      padding: 50px 87px !important;
      margin-top: 5%;
  }
  .modal-dialog{
      max-width: 652px;
  }
  .form-control{
     padding-left: 1rem;
     height: 50px;
     font-family: $font-family;
     font-size: 15px;
     letter-spacing: 0.38px;
     color: #000000;
  }
  .scrollit{
      max-height: 540px;
      overflow: hidden;
      margin-bottom: 10px;
      padding-right:2px;
      margin-right: 15px;
      cursor: pointer;
      &:hover{
          overflow-y: auto;
      }
  }
  .table-max{
    max-height: 540px;
    overflow-y: scroll;
    overflow-x: hidden;
    padding-right: 2px;
    border-top: solid 1px transparent;
    &::before {
      width: 100%;
      height: 1px;
      position: sticky;
      left: 0;
      content: "";
      top: 0;
      background-color: #373a3d;
      display: block;
      margin-top: -1px;
      z-index: 9;
    }
  }
  .table-curtain {
    position: relative;
    &::before {
      content: "";
      height: 2px;
      background-color: #000000;
      left: 0;
      right: 0;
    }
  }
  .table{
      background-color: #252525;
      text-align: left;
      font-family: $font-family;
      margin-bottom: 0px !important;
      th {
        border-right: 1px solid rgb(55 58 61);
      }
      .tbody {
          height: 100px;
          overflow-y: auto;
          overflow-x: hidden;
      }
      .no-records-alert{
          color: #E72729;
          font-family: $font-family;
          font-size: 16px;
      }
  }
  }

.mt-10 {
  margin-top: 10px;
}
/* form css */
.btn-red {
  background: $bg-red;
}

.text-red{
    color: $bg-red;
}

.btn-black{
    background: $bg-black;
}

@mixin login-home-formlabel {
  font-family: $font-family;
  font-size: 15px;
  line-height: 18px;
  font-weight: medium;
  letter-spacing: 0.75px;
  color: white;
  text-transform: uppercase;
}

@mixin login-home-forminput {
  border-radius: 5px;
  height: 31px;
}

@mixin form-btn {
  box-shadow: 0px 0px 10px #00000033;
  border-radius: 10px;
  font-family: $font-family;
  font-size: 15px;
  line-height: 18px;
  letter-spacing: 0.75px;
  font-weight: 800;
  text-transform: uppercase;
  color: white;
  width: 190px;
  height: 60px;
  margin-top: 14px;
}

/* custom select */
@mixin custom-select {
  select.input-lg {
    -webkit-appearance: none;
    -moz-appearance: none;
    -o-appearance: none;
  }
  .form-group {
    position: relative;
  }
  .form-control {
    width: 183px;
    height: 35px;
    font-family: $font-family;
    font-size: 15px;
    letter-spacing: 0.38px;
    // text-transform: uppercase;
  }
  select + img {
    position: absolute;
    bottom: 6px;
    margin-left: 150px;
    pointer-events: none;
    background-color: unset;
    padding-right: 5px;
  }
}
.login-block {
  .login-form {
    label {
      @include login-home-formlabel;
    }
    input {
      @include login-home-forminput;
    }
    .btn {
      @include form-btn;
    }
  }
  .login-trouble-link {
    margin-top: 30px;
    a {
      font-family: $font-family;
      font-size: 14px;
      letter-spacing: 0px;
      color: white;
    }
  }
}

.home-block {
  .home-form {
    label {
      @include login-home-formlabel;
    }
    @include custom-select;
  }
  .btn {
    @include form-btn;
  }
}

  .cursor-pointer {
    cursor: pointer
  }

  .sort-direction {
    display: inline-block;
    height: 0;
    width: 0;
    margin: 5px;
    border-left: 5px solid transparent;
    border-right: 5px solid transparent;
    vertical-align: middle;

    &.asc {
      border-bottom: 5px solid white;
    }

    &.desc {
      border-top: 5px solid white;
    }

    &.default {
      border-top: 5px solid #9aa0ac;
    }
  }

  .pipe {
    color: white;
    margin-left: 10px;
    margin-right: 10px;
  }
  .disable {
    cursor: auto;
    color: grey !important;
  }
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
  border: 1px solid rgba(0, 40, 100, 0.12);
  -webkit-text-fill-color: black;
  -webkit-box-shadow: 0 0 0px 1000px white inset;
  transition: background-color 5000s ease-in-out 0s;
  caret-color: black;
}

.cursor-pt {
  cursor: pointer;
}

.cursor-na {
    cursor: not-allowed;
}

.logout-button {
    margin: 5px;
    float: right;
    position: absolute;
    right: 5px;
    top: 5px;
}
.toaster {
    font-family: $font-family !important;
    font-size: 16px;
}

.bg-transparent {
  background-color: transparent !important;
}

.mb-25 {
  margin-bottom: 25px;
}

.tx-centre {
  text-align: center;
}
.desc {
  font-family: $font-family-base;
  font-size: 16px;
  color: white;
  text-align: left;
}
.mg-left {
  margin-left: -12%;
}
.mg-bt-40 {
  margin-bottom: 40px;
}

.modal-dialog {
  margin: auto;
}
.mg-left {
  margin-left: -12%;
}
.mg-bt-40 {
  margin-bottom: 40px;
}

.text-capitalize {
    text-transform: capitalize !important;
}
.mg-to {
    margin-top: 0px !important;
}
.header-sticky {
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  background: #1d1d1d;
  border: unset;
}
.cr-rd {
    margin-top: 21px;
    font-size: 15px;
    line-height: 18px;
    letter-spacing: 0.38px;
    font-weight: 800;
    color: #E72729;
    padding-left: 0.6rem;
}
.mg-tp {
    margin-top: 15px;
}
.cr-wh{
    color: white !important;
}
.spinner-div {
    min-height: 110px;
    display: block;
}
.style::-webkit-scrollbar-track
{
    border-radius: 35px;
}

.style::-webkit-scrollbar
{
    width: 5px;
}

.style::-webkit-scrollbar-thumb
{
    border-radius: 35px;
    // background-color: #D62929;
    background-color: grey;

}
.change-password-title {
  text-transform: capitalize;
}

.select-dd {
  width: 183px;
  font-family: $font-family;
  font-size: 15px;
  letter-spacing: 0.38px;
}
.select-mdd {
  width: 150px;
  font-family: $font-family;
  font-size: 15px;
  letter-spacing: 0.38px;
}
.select-sdd {
  width: 90px;
  font-family: $font-family;
  font-size: 15px;
  letter-spacing: 0.38px;
}
.select-ldd {
  width: 275px;
  font-family: $font-family;
  font-size: 15px;
  letter-spacing: 0.38px;
}
.select-xsdd {
  width: 70px;
  font-family: $font-family;
  font-size: 15px;
  letter-spacing: 0.38px;
}

[list]::-webkit-calendar-picker-indicator {
  display: none;
}
.mt-30px {
  margin-top: 30px;
}

// Scrollbar css Start
body *::-webkit-scrollbar {
  width: 6px;
  height: 6px;
  transition: 0.3s background;
}

body *::-webkit-scrollbar-thumb {
  background: $gray-400;
}

::-webkit-scrollbar {
  width: 5px;
  height: 7px;
}

::-webkit-scrollbar-button {
  width: 0px;
  height: 0px;
}

::-webkit-scrollbar-thumb {
  background: #525965;
  border: 0px none #ffffff;
  border-radius: 0px;

  &:hover,
  &:active {
      background: #525965;
  }
}

::-webkit-scrollbar-track {
  background: transparent;
  border: 0px none #ffffff;
  border-radius: 50px;

  &:hover,
  &:active {
      background: transparent;
  }
}

::-webkit-scrollbar-corner {
  background: transparent;
}

// Scrollbar css End
.zones-list-data {
  overflow: auto;
  max-height: 150px;
  max-height: 150px;
  // overflow: hidden;
  margin-bottom: 10px;
  padding-right:2px;
  margin-right: 15px;
  cursor: pointer;
  &:hover{
      overflow-y: auto;
  }
}
.request-block{
  .request-form{
      label{
          @include login-home-formlabel;
      }
      input{
          @include login-home-forminput;
      }
      .btn{
          @include form-btn;
      }
  }
}

@mixin modal-title{
  h2{
    font-family: "proxima-nova";
    font-size: 50px;
    line-height: 61px;
    font-weight: bold;
    letter-spacing: 0px;
    width: 100%;
    margin-top: 16px;
  }
}
/* requestaccess page */
.requestaccess-page{
  @include login-homepage;
  .request-block{
    border-radius: 50px;
    padding: 50px 87px !important;
    @include home-block;
    width: 800px !important;
    .request-title{
        @include modal-title;
    }
  }
}
.set-password {
  @include theme-bg;
  .modal-dialog {
    max-width: unset;
    margin: 0 auto;
    width: 615px;
    .modal-content{
      background: transparent;
    }
  }
}
.display-none {
  display: none;
}
.notFound{
    left: 0;
    line-height: 200px;
    margin-top: -100px;
    position: absolute;
    text-align: center;
    top: 50%;
    width: 100%;
    font-size: 100px;
    color: grey;
}
.sidenavbar {
  height: 100%;
  width: 200px;
  position: fixed;
  z-index: 2;
  top: 0;
  left: 0;
  background-color:white;
  overflow-x: hidden;
  padding-top: 70px;
  transition: 0.5s;
  height: 480px;
  a{
      padding: 10px 0 10px 32px;
      text-decoration: none;
      display: block;
      transition: 0.3s;
      text-align: left;
      font-family: $font-family;
      font-size: 12px;
      line-height: 14px;
      letter-spacing: 0px;
      color: #252525;
      &:hover{
          background: #F2F2F2 0% 0% no-repeat padding-box;
      }
  }
  .closebtn {
      position: absolute;
      top: 0;
      padding: 32px 0 0 32px;
      float: left;
      &:hover{
          background: unset;
      }
      .fa {
          font-size: 21px !important;
          color: #E72729;
          }
      }
}
.toggle-button {
  margin: 1.2rem 0 1rem 0rem;
}
.loadingOpacity {
  opacity: 0.2;
}
.avg-max {
    padding-left: 0;
    color: white;
    font-size: 12px !important;
    line-height: 14px !important;
    letter-spacing: 0.6px;
    margin-right: 40px;
    white-space:nowrap;
}
.text-eclips{
  white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}
.close{
  padding: 0;
  background-color: transparent;
  border: 0;
  opacity: 1;
  color: $bg-red;
  text-shadow: none;
  &:focus{
      outline: unset;
      opacity: 1;
  }
}
.margin-lft {
  .modal-dialog {
    margin-left: 20% !important;
  }
}
.footer {
  color: #fff;
}
.footer a:not(.btn) {
  color: #6e7687;
}
.max-conf-name{
  max-width: 385px;
}
.max-conf-wt {
  max-width: 363px;
}
.conf-ht{
  height: 25px;
}
.mg-top-15{
  margin-top: 0.10rem;
}
.br-rad {
  border-radius: 5px;
}

.icon-sm {
  font-size: 16px;
}

// Not Found content styles

.not-found-page {
  min-height: 100vh;
  padding: 60px 0 98px;
  position: relative;
  display: flex;
  justify-content: center;
  color: #fff;
  .logo {
    width: 180px;
  }
  .not-found-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: center
  }
  .not-found-wrapper {
    align-items: center
  }
  .not-found-title {
    font-size: 5rem;
    font-weight: 100;
    margin-bottom: .75rem;
    padding-top: .75rem
  }
  
  .not-found-message {
    font-size: 21px;
    font-weight: 300;
    margin-bottom: 4rem;
    padding: 0 3rem;
    text-align: center;
  }
}


.back-button {
  align-items: center;
  border: 2px solid var(--color-4);
  border-radius: 2rem;
  display: flex;
  font-size: 12px!important;
  font-weight: 750!important;
  height: 38px;
  letter-spacing: .5px;
  padding: .5rem 1.5rem;
  text-decoration: none
}

.left-arrow-icon {
  fill: #fff;
  display: flex;
  font-size: 1rem;
  margin-right: .75rem
}

.not-found-footer {
  align-items: center;
  background: #252525;
  bottom: 0;
  display: flex;
  font-size: 11px;
  height: 38px;
  justify-content: center;
  padding: .25rem .75rem 1rem;
  position: fixed;
  width: 100%
}

.not-found-footer .footer {
  padding: 0
}

.disable-image {
  opacity: 0.5;
  pointer-events: none;
}

.w-270 {
  width: 270px !important;
}

.pt-10 {
  padding-top: 10px;
}

.border-top-0 {
  border-top: 0;
  border-bottom-width: 1px!important;
}

.table-dark th, .table-dark td, .table-dark thead th {
  border-color: rgb(55 58 61);
}
